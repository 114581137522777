import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Alert from '@mui/material/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: 5,
  boxShadow: 24,
  p: 3,
};



export default function IntialAmountPopup({ openAmountPopup, setOpenApprove, InitialAmountId, handleCloseInitialAmount, setId, getData }) {
  const [textVal, setTextVal] = React.useState('');  
  const token = localStorage.getItem('accessToken');
 

  // const [totalAmount, setTotalAmount] = useState(0);

  const [initialAmount, setInitialAmount] = useState('');
  const [pendingAmount, setPendingAmount] = useState(null);
  const [initialPaymentList ,setIntialPaymentStatus] = useState([]);
  // const [intialPendingData ,setInitialPendingData] = useState([]);

  const handleCloseModal = () => {
    // setTotalAmount(0);
    // setInitialAmount('');
    window.location.reload();
     // Set text to an empty string when the modal is closed
     // Set text to an empty string when the modal is closed
    handleCloseInitialAmount();
  }; 





 



  const InitialPaymentList = async (id) => {
    
    try {
      const resultData = await axios.get(
        `${process.env.REACT_APP_API}admin/get_partial_payments_list/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-api-key': process.env.REACT_APP_API_KEY,
            platform: process.env.REACT_APP_platform,
          },
        }
      );

      console.log(resultData, "Result Data");

      if (resultData?.data?.status) {
         setIntialPaymentStatus(resultData?.data?.data);
      }

    } catch (error) {
      console.log(error);
    }

  };


  // const GetTotalAndPendingAmount = async (id) => {

  //   try {
  //     const resultPendingAmount = await axios.get(
  //       `${process.env.REACT_APP_API}admin/get_project_payment_info_by_id/${id}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           'x-api-key': process.env.REACT_APP_API_KEY,
  //           platform: process.env.REACT_APP_platform,
  //         },
  //       }
  //     );

  //     console.log(resultPendingAmount, "Result Pending Data_________");

  //     if (resultPendingAmount?.data?.status) {
  //         setInitialPendingData(resultPendingAmount?.data?.data);
  //         // setTotalAmount(resultPendingAmount?.data?.data.totalAmount);

  //       //  setIntialPaymentStatus(resultPendingAmount?.data?.data);
  //     }

  //   } catch (error) {
  //     console.log(error);
  //   }

  // };

  // console.log(intialPendingData, "intialPendingData_________");

  useEffect(() => {

    InitialPaymentList(InitialAmountId);
    // GetTotalAndPendingAmount(InitialAmountId);

  }, [InitialAmountId]);

  // useEffect(() => {
  //   if (intialPendingData?.isTotalAmount) {
  //     // Execute code when isTotalAmount is true
  //     setPendingAmount(intialPendingData?.pendingAmount ?? intialPendingData?.pendingAmount);
  //   } else {
  //     // Execute code when isTotalAmount is false
  //     setPendingAmount(totalAmount);
  //   }
  // }, [intialPendingData?.isTotalAmount, intialPendingData?.totalAmount, intialPendingData?.pendingAmount]);
 

  // console.log(pendingAmount,"_________Pending Amount");

  // const handleTotalChange = (e) => {
  //   let inputValue = e.target.value;
  //   // Replace any non-digit character with an empty string
  //   inputValue = inputValue.replace(/\D/g, '');
  //   // Ensure the input is not empty
  //   if (inputValue === '') {
  //     setTotalAmount('');
  //   } else if (parseInt(inputValue) >= 1) {
  //     // If input is a number greater than or equal to 1
  //     setTotalAmount(inputValue);
  //     setPendingAmount(inputValue);
  //   } else {
  //     // If input is less than 1, set to 1
  //     setTotalAmount('1');
  //   }
  // };

  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    // Replace any non-digit character with an empty string
    inputValue = inputValue.replace(/\D/g, '');
    // Ensure the input is not empty
    if (inputValue === '') {
        setInitialAmount('');
    } else if (parseInt(inputValue) >= 1) {
      // If input is a number greater than or equal to 1
      setInitialAmount(inputValue);
      // const pending = pendingAmount - parseFloat(inputValue || 0);
      // const pending = pendingAmount - parseFloat(inputValue || 0);
      
      // setPendingAmount(pending < 0 ? 0 : pending); // Ensure pending amount doesn't go below 0
    } else {
      // If input is less than 1, set to 1
      setInitialAmount('1');
    }
  };


  const handlePay = async () => {
    // if (parseFloat(initialAmount) <= pendingAmount){
      // Proceed with payment
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API}admin/add_partial_payments`,
          {
            id: InitialAmountId,
            // amount: totalAmount,
            partialAmount: initialAmount
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'x-api-key': process.env.REACT_APP_API_KEY,
              platform: process.env.REACT_APP_platform,
            },
          }
        );
  
        if (res?.data?.status) {
              setInitialAmount('');
              getData();
              handleCloseInitialAmount();
              toast.success(res?.data?.message);
              setTimeout(() => {  
                window.location.reload();
              }, 200);
        }
  
      } catch (error) {
        console.log(error);
      }

    // } else {

    //   console.log('Initial amount cannot be greater than total amount');
    // }
  };






  return (
    <div>
      <Modal
        open={openAmountPopup}
        onClose={handleCloseModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}
          >
           <div> 
            {/* <div className='AmontRpt'>
                <p style={{ fontWeight: 'bold' }}>Total Amount($) </p>
               
            {intialPendingData?.isTotalAmount === true ? <>        
                  <input                
                    id='outlined-basic'
                    placeholder='Enter Amount'
                    variant='outlined'
                    onChange={handleTotalChange}
                    style={{ marginBottom: '10px', outline: 'none' }}
                    value={totalAmount}
                    disabled={intialPendingData?.isTotalAmount}
                /> </> : 
                <>
                  <input                
                    id='outlined-basic'
                    placeholder='Enter Amount'
                    variant='outlined'
                    onChange={handleTotalChange}
                    style={{ marginBottom: '10px', outline: 'none' }}
                    value={totalAmount}
                />
                </> }

            </div> */}

            <div className='AmontRpt'>
                <p style={{ fontWeight: 'bold' }}>Current Amount($) </p>
                <input
                    id='outlined-basic'
                    placeholder='Enter Amount'
                    variant='outlined'
                    style={{ marginBottom: '10px', outline: 'none' }}
                    value={initialAmount}
                    onChange={handleInputChange}
                    />
            </div>

            <div className='AmontRpt'>
                {/* <p style={{ fontWeight: 'bold' }}>Pending Partial Amount: ${intialPendingData?.pendingAmount}  </p> */}
            </div>
            <div className='Mbtion' style={{ width: '100%' }}>
              <Button
                variant='contained'
                style={{ width: '20%' }}
                onClick={handlePay}
                // disabled={parseFloat(initialAmount) > parseFloat(pendingAmount)}
                // onClick={() => addPosts()}
              >
                Pay
              </Button>

              <Button
                
                variant='contained'
                style={{ width: '20%', marginLeft: '5px' }}
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </div>
            </div>

            {initialPaymentList.length === 0 ? <> <p className='mt-3'> No Current Payment </p> </> : <>

            <div className='TableListData'>

              <h5> Current  Payments List </h5>

               <table id='customers'>
                  <thead>
                     <tr>
                       <th> id </th>
                       <th> Current  Amount </th>
                       <th> Payment Status </th>
                       <th> Date  </th>
                     </tr>
                  </thead>

                  <tbody> 
                    {initialPaymentList?.map((PayListData, index) => (

                        <tr key={index}>
                          <td> {PayListData?.id} </td>
                          <td> ${PayListData?.partialAmount ?? 0} </td>
                          <td> {PayListData?.paymentStatus} </td>
                          <td>{PayListData?.createdAt ? new Date(PayListData.createdAt).toDateString() : ''}</td>
                        </tr>

                    ))}
  
                  </tbody>
               </table>

            </div>
            </>}

          </div>
          
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
