import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Alert from "@mui/material/Alert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 3,
  height: 400,
};

export default function ImageModal({
  openImage,
  setopenImage,
  handleOpenImage,
  handleCloseImage,
  imageZoom,
}) {
  const openTab = () => {
    window.open(imageZoom, "_blank");
  };

  let video = (imageZoom.includes("mp4"))
  return (
    <div>
      <Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {video ? 

          
        <video controls width="250" style={{height:"100%",width:"100%"}}
    src={imageZoom} type="video/webm" onClick={openTab}>
          
  

  
</video>

:  <img
src={imageZoom}
style={{
  height: "100%",
  width: "100%",
  objectFit: "cover",
  cursor: "pointer",
  borderRadius:"20px"
}}
onClick={openTab}
/>
}
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
