import { useNavigate } from 'react-router-dom';

const Logout = (props) => {
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem('accessToken');
    navigate('/');
  };
  return (
    <div>
      <div className='d-flex justify-content-end wdthPsreMn'>
        <button
          onClick={logoutHandler}
          className='btn fw-bold btn-primary px-5  rounded'
        >
          <span className='text-lg'>Logout</span>
        </button>
      </div>
      {props.children}
    </div>
  );
};

export default Logout;
