import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const Loader = ({ size = 12, color = 'primary', thickness = 6 }) => {
  const diameter = `${size}rem`;
  const border = `${thickness}px solid ${color}`;

  return (
    <div style={{zIndex: 1000}} className='position-fixed start-0 top-0 d-flex align-items-center justify-content-center bg-dark bg-opacity-50 vw-100 vh-100'>
      <div
        className={`spinner-border text-${color}`}
        role='status'
        style={{ width: diameter, height: diameter, borderWidth: thickness }}
      >
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
